import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

export default function AlternateTimeline() {
  return (
    <Timeline position="alternate">
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot variant="outlined" />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>Eat</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        {/* there are only `primary` & `secondary` colors here */}
        <TimelineSeparator>
          <TimelineDot variant="outlined" color="primary" style={{ borderColor: "#c792ea" }} />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>Code</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot variant="outlined" color="secondary" style={{ borderColor: "#c3e88d" }} />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>Sleep</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color='secondary' style={{ borderColor: "#c792ea" }} />
        </TimelineSeparator>
        <TimelineContent></TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot style={{ borderColor: "#ffd700" }} variant="outlined" />
        </TimelineSeparator>
        <TimelineContent></TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot variant="outlined" style={{ borderColor: "#c792ea" }} />
          <TimelineDot variant="outlined" style={{ borderColor: "#c3e88d" }} />
          <TimelineDot color='warning' style={{ opacity: 0.5, borderColor: "#ffd700" }} />
        </TimelineSeparator>
        <TimelineDot color='info' />
        <TimelineContent>Code</TimelineContent>
      </TimelineItem>      
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color='secondary' style={{ borderColor: "#c3e88d" }} />
        </TimelineSeparator>
        <TimelineContent>Repeat</TimelineContent>
      </TimelineItem>    
    </Timeline>
  );
}
